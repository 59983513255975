//=require jquery.min.js
//=require wow.js
//=require svg.js

// require what-input.min.js

//=require slick.min.js
//=require fullpage.js

//================ velux code ================//
[].forEach.call(document.querySelectorAll('img[data-src]'),    function(img) {
    img.setAttribute('src', img.getAttribute('data-src'));
    img.onload = function() {
        img.removeAttribute('data-src');
    };
});

;


;(function ($) {
    $(document).ready(function () {

        //    SVG DRAWING

        let svgHeight = $('#fullpage').height(),
            svgWidth = $('#fullpage').width(),
            windowHeight = $(window).height(),
            windowWidth = $(window).width();




        var draw,
            path,
            pathLength = 0;


        // draw svg path

        function createPath(){
            let $sections = $('.section'),
                sectionsPosition = [],
                startPositionX = svgWidth/3,
                pathLine = '',
                containerRight = $('.container')[0].getBoundingClientRect().right;;


            $sections.each(function(index, section){
                sectionsPosition.push($(section).offset().top);
            });

            pathLine = 'M' +startPositionX + ' 0' ;

            for (let i = 0; i < sectionsPosition.length; i++){

                if ( i == 3){
                    pathLine += ' v50 h300'
                }
                if ( i == 4){
                    pathLine += ' v50 h-300'
                }

                pathLine += ' V' + sectionsPosition[i];
                if ( i == sectionsPosition.length-1 ){
                    pathLine += ' v50 H' + (windowWidth-100);
                }
            }

            pathLine += ' V' + svgHeight;

            draw = SVG('svg-line').size(svgWidth, svgHeight);

            path = draw.path(pathLine);
            pathLength = path.length();

            path.stroke({
                width: 4,
                color: 'red',
                dasharray: pathLength,
                dashoffset: pathLength
            });
        };


        function drawPath(){
            svgHeight = $('#fullpage').height(),
                svgWidth = $('#fullpage').width();

            draw = SVG('svg-line').size(svgWidth, svgHeight);
            line = draw.line(svgWidth/2, 0, svgWidth/2, svgHeight).stroke({
                width: 4,
                dasharray: svgHeight,
                dashoffset: svgHeight,
                color: 'red'
            });
        }



        if($(window).width() > 991){
            $('#fullpage').fullpage({
                //options here
                navigation:true,
                navigationPosition: 'left',
                scrollBar: true,
                scrollingSpeed: 1700,
                afterRender: function(){
                    new WOW().init();

                    svgHeight = $('#fullpage').height();
                    svgWidth = $('#fullpage').width();
                    windowHeight = $(window).height();

                    createPath();
                },
                onLeave: function (origin, destination, direction) {

                    let
                        koff = svgHeight / pathLength,
                        windowOffset = (svgHeight - $(destination.item).offset().top) ;


                    windowOffset = (pathLength - $(destination.item).offset().top / koff);

                    if(destination.isLast){
                        windowOffset = 0;
                    }

                    path.animate(700, '-', 0).stroke({
                        dashoffset: windowOffset,
                    });
                }

            });
        }

        $(window).on('resize', function(){

            if($(window).width() > 991){
                $('#fullpage').fullpage({
                    //options here
                    navigation:true,
                    navigationPosition: 'left',
                    scrollBar: true,
                    scrollingSpeed: 1700,
                    afterRender: function(){
                        new WOW().init();

                        svgHeight = $('#fullpage').height();
                        svgWidth = $('#fullpage').width();
                        windowHeight = $(window).height();

                        createPath();
                    },
                    onLeave: function (origin, destination, direction) {
                        let
                            koff = svgHeight / pathLength,
                            windowOffset = (svgHeight - $(destination.item).offset().top) ;


                        windowOffset = (pathLength - $(destination.item).offset().top / koff);

                        if(destination.isLast){
                            windowOffset = 0;
                        }

                        path.animate(700, '-', 0).stroke({
                            dashoffset: windowOffset,
                        });
                    }
                });
            } else {
                if ( $('.fp-enabled').length ) {
                    // Destroy all
                    $.fn.fullpage.destroy('all');
                }
            }
        });



        $('.scroll-btn').click(function(){
            if($(window).width() < 991){
                if (pagePositon+1 <= pageMaxPosition) {
                    pagePositon++;
                    $('html, body').stop().animate({
                        scrollTop: $scrollItems.eq(pagePositon).offset().top
                    }, 800);
                }
            } else {
                $.fn.fullpage.moveSectionDown();
            }
        });


        let pagePositon = 0,
            sectionsSeclector = '.section',
            $scrollItems = $(sectionsSeclector),
            offsetTolorence = 30,
            pageMaxPosition = $scrollItems.length - 1;
        // //Map the sections:
        $scrollItems.each(function(index,ele) { $(ele).attr("scroll-attr",index).data("pos",index); });

        // Bind to scroll
        $(window).bind('scroll',upPos);

        //Move on click:

        //Update position func:
        function upPos(){
            var fromTop = $(this).scrollTop();
            var $cur = null;
            $scrollItems.each(function(index,ele){
                if ($(ele).offset().top < fromTop + offsetTolorence) $cur = $(ele);
            });
            if ($cur != null && pagePositon != $cur.data('pos')) {
                pagePositon = $cur.data('pos');
            }
        }

    })
})(jQuery);
